<script>
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';

Vue.use(VueChatScroll);

const store = {
  state: {
    loading: false,
    pdf: true,
    file_name: '',
  },
};
export default {
  components: {
  },
  data() {
    return {
      message: '',
      store,
    };
  },
  methods: {
  },
  beforeMount() {
  },
};
</script>
<template lang="html">
  <div style="height: calc(100vh - 261px)">
    <iframe
        type=""
        style="width: 100%; height: 100%"
        :src="store.state.pdf"
        :title="store.state.file_name + '#view=FitH'"/>
  </div>
</template>
<style lang="scss">
</style>
